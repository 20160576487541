import Layout_public from '@/component/layout/layout_public/layout_public';
import Re_lister from '@/component/re/re_lister/re_lister';
import { T_component_with_page_layout } from '@/declaration/type/t_component_with_page_layout';
import { fn_get_static_props_common_ns } from '@/state/page/fn_get_static_props_common_ns';
import { title_build } from '@/utility/browser/title_build';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import React, { HTMLAttributes, memo, useMemo } from 'react';

export interface I_Home extends HTMLAttributes<HTMLDivElement> {}

const Index: T_component_with_page_layout<I_Home> = memo<I_Home>(() => {
  const { t } = useTranslation();
  const _name = useMemo(() => t('router:home'), [t]);

  return (
    <>
      <Head>
        <title>{title_build([_name])}</title>
      </Head>
      <div className="c gap-3" data-testid="Index">
        <Re_lister />
      </div>
    </>
  );
});

Index.get_layout = (page) => <Layout_public>{page}</Layout_public>;
export const getStaticProps: GetStaticProps<any, any> = fn_get_static_props_common_ns();
export default Index;
