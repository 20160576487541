import { loading } from '@/utility/dynamic/loading';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import React, { CSSProperties, FC, HTMLAttributes, memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { I_Nav_main } from '../../nav/nav_main/nav_main';

export interface I_Layout_public extends HTMLAttributes<HTMLDivElement> {
  i_nav_main?: I_Nav_main;
  class_side_nav?: string;
  class_main?: string;
  nav_unlock?: boolean;
  nav_height?: CSSProperties['height'];
  no_footer?: boolean;
  no_nav_main?: boolean;
}

const Nav_main = dynamic(() => import('@/component/nav/nav_main/nav_main'), { loading });
const Footer = dynamic(() => import('@/component/footer/footer'), { loading });

const Layout_public: FC<I_Layout_public> = memo<I_Layout_public>((props: I_Layout_public) => {
  const { children, i_nav_main, class_main, nav_unlock, nav_height = '50px', no_footer, no_nav_main } = props;
  const query = useSearchParams();
  const embed = useMemo(() => query.get('embed') === '1', [query]);
  const padding = useMemo(() => query.get('padding'), [query]);
  const style = useMemo(() => {
    const r: CSSProperties = {
      marginTop: embed ? undefined : nav_height,
    };
    if (embed) {
      if (padding) {
        r.padding = padding;
      }
    }
    return r;
  }, [embed, nav_height, padding]);

  return (
    <div data-testid="Layout_public">
      {!embed && !no_nav_main && <Nav_main {...i_nav_main} height={nav_height} fixed={!nav_unlock} ctn />}
      <div style={style} className={twMerge(embed ? '' : `ctn`, class_main)}>
        {children}
      </div>
      {!embed && !no_footer && (
        <div className="mt-5 block">
          <Footer />
        </div>
      )}
    </div>
  );
});

export default Layout_public;
